.te {
   .header-right-actions-block {
      @include media-breakpoint-up(md) {
         .headerSubscribeBtn {
            max-width: 160px;
         }
      }
   }


   @include media-breakpoint-up(md) {
      .add-fav-btn {
         width: 210px;
      }
   }


   .header-my-account {
      .my-account-item-link {
         line-height: 2;
         padding-top: 5px;
         padding-bottom: 5px;
      }

   }


}
