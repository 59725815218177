@import "../../../assets/styles/mixins";

.user-info-block {
   display: flex;
   align-items: center;

   .user-info-icon {
      float: left;
      /* width: 80px; */
   }

   .user-name-phone-block {
      float: left;
      /* width: calc(100% - 80px); */
   }

   .user-name {
      color: var(--text-color);
   }

   .user-phone {
      font-size: $font-size-base;
      color: var(--text-gray-color);
   }

   .userNameBtn {
      color: var(--text-gray-color);
      text-align: left;
      font-size: $font-size-base + 0.7;
      font-weight: 400;
      padding: 0;
      line-height: 1.2;

      .edit-user-name {
         /* border: 1px solid #666; */
         width: 25px;
         height: 25px;
         padding: 3px;
         display: inline-flex;
         align-items: center;
         justify-content: center;
         border-radius: 100px;
      }

      &:hover {
         .edit-user-name {
            background-color: var(--secondary);
         }
      }
   }
}

.subscribe-now-block {
   position: relative;
   border-radius: var(--border-radius);
   overflow: hidden;

   &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      background-color: var(--bg);
      filter: brightness(0.85);
   }
}

.subscribe-now-content {
   position: relative;
   z-index: 3;
   .subscribe-now-content-title {
      color: var(--text-color);
      font-size: $font-size-base + 0.4;
      text-align: center;
      padding-bottom: 7px;
   }

   .subscribe-now-btn {
      background-color: var(--primary);
      color: white;
      width: 100%;
      text-decoration: none;
      border: none;
      display: block;
      text-align: center;
      line-height: 1;
      padding: 13px 20px;
      border-radius: 5px;
      font-size: $font-size-base + 0.2;

      &:hover {
         text-decoration: none;
         opacity: 0.9;
      }
   }
}

.transactions-toggle-btn {
   padding: 10px 20px;
   font-size: $font-size-base + 0.2;
   border-color: var(--border-gray-2);
   background-color: var(--bg);
   position: relative;
   color: var(--text-gray-color);

   svg {
         color: var(--text-gray-color) !important;
         fill: var(--text-gray-color) !important;
      }

   &:hover {
      color: var(--text-color);
      filter: brightness(0.8);
      border-color: var(--border-color-2);

      &.logout-btn {
         background-color: var(--danger);
         filter: brightness(1);
         border-color: var(--danger);
      }



      svg {
         color: var(--text-color) !important;
         fill: var(--text-color) !important;
      }
   }
}

.user-subscription-list {
   li {
      float: left;
      width: 100%;
      margin-bottom: 20px;

      &.header-row {
         display: none;
      }
   }

   @include media-breakpoint-up(md) {
      li {
         width: calc(50% - 10px);

         &:nth-child(odd) {
            margin-left: 20px;
         }
      }
   }

   @include media-breakpoint-up(lg) {
      li {
         width: 100%;
         margin-bottom: 0;

         &:nth-child(odd) {
            margin-left: 0;
         }

         &.header-row {
            display: block;
            font-weight: 700;
         }
      }
   }
}

.each-subscription-item {
   border: 1px solid var(--border-color-2);
   border-radius: var(--border-radius);
   padding: 20px;
   background-color: var(--bg);

   .each-subscription-col {
      float: left;
      width: 100%;
      padding-top: 22px;
      position: relative;
      margin-bottom: 10px;

      &::before {
         content: attr(data-name);
         position: absolute;
         top: 0;
         left: 0;
         font-size: $font-size-base - 0.1;
         color: var(--text-gray-color-2);
      }
   }

   @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      margin-top: -1px;
      border-radius: 0;
      padding-top: 15px;
      padding-bottom: 15px;


      .each-subscription-col {
         padding-top: 0;
         margin-bottom: 0;

         &::before {
            display: none;
         }
         &.date {
            width: 20%;
         }

         &.desc {
            width: 30%;
         }

         &.service-period {
            width: 20%;
         }
         &.sub-total {
            width: 15%;
            text-align: right;
         }

         &.total {
            width: 15%;
            text-align: right;
            padding-right: 25px;
         }

      }

      &:hover {
         background-color: var(--bg-2);
      }

   }
}

.active-subscription-block {
   margin-bottom: 20px;
}

.subscription-expiry-info {
   font-size: $font-size-base + 0.6;
   color: var(--warning);
   font-weight: 700;
   display: flex;
   align-items: center;

   svg {
      width: 30px !important;
      height: 30px !important;
      fill: var(--warning) !important;
   }
}

.expireSubscriptionBtn {
      background-color: var(--primary);
      color: var(--text-color);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      line-height: 1;
      font-size: $font-size-base;
      margin-left: 8px;
      border-radius: 3px;

      &:hover {
         text-decoration: none;
         background-color: var(--danger);
         color: var(--text-color);
      }
   }

.first-subscription-info {
   font-size: $font-size-base + 0.5;
   color: var(--text-gray-color-2);

   .expireSubscriptionBtn {
      font-size: $font-size-base + 0.2;
      margin-top: 15px;
      padding: 15px 25px;
   }
}

.active-subscription {
   .subscription-expiry-info {
      color: #83e85a;
   }

   svg {
      fill: #83e85a !important;
      margin-right: 10px;
   }
}