@import "../../../assets/styles/mixins";

.profiles-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.each-profile-btn {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  margin: 15px 20px;
  position: relative;
  margin-bottom: 20px;

  .each-profile-pic {
    width: 120px;
    height: 120px;
    background-color: var(--secondary);
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    img {
      width: 100%;
    }
  }

  .editProfileIconBlock {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 28px;
    height: 28px;
    border-radius: 100px;
    background-color: var(--primary);
    transform: scale(0);
    transition: all 0.3s ease;

    svg {
      width: 20px;
      height: 20px;
      color: var(--text-color);
      position: relative;
      z-index: 3;
    }
  }

  .each-profile-name {
    padding-top: 10px;
    font-size: $font-size-base;
    color: var(--text-gray-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .lockIcon {
      width: 15px;
      height: 15px;
      margin-right: 7px;
    }
  }

  &.edit-profile {
    .editProfileIconBlock {
      transform: scale(1);
    }

    .each-profile-pic {
      transform: scale(0.9);
    }

    &.permanent-profile {
      opacity: 0.5;

      .each-profile-pic {
        transform: scale(1);

      }

    }

    &:hover {
      .each-profile-pic {
        transform: scale(1);
      }
    }
  }
}

.add-new-profile-btn {
  .each-profile-pic {
    background-color: transparent;
    border-radius: 5px;
    border: 2px dashed var(--border-color-2);

    img {
      width: 80%;
      opacity: 0.7;
    }
  }

  &:hover {
    .each-profile-pic {
      border-color: var(--text-color);
      img {
        opacity: 1;
      }
    }
  }
}

.manage-profiles-btn {
  border: 1px solid var(--border-color-2);
  padding: 10px 10px;
  font-size: $font-size-base - 0.1;
  color: var(--text-gray-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  margin: 0 7px 20px 7px;

  &:active,
  &:visited,
  &:focus {
    border-color: var(--border-color-2);
  }

  &:hover {
    border-color: var(--text-color);
    color: var(--text-color);
  }

  .addPlusIcon {
    width: 15px;
    margin-right: 6px;
  }
}

.profileNameInput {
  font-size: $font-size-base + 0.7;
}

.addProfileBlock {
  .submit-phone-btn {
    width: 200px;
  }

  .cancelAddProfile {
    width: 150px;
    border: 1px solid var(--border-color-2);
    color: var(--text-gray-color);
    padding: 10px 10px;
    height: 40px;
    text-align: center;
    margin-left: 15px;

    &:hover {
      color: var(--text-color);
      border-color: var(--text-color);
    }
  }
}

.permanent-profile {
  cursor: default;
}

.delete-profile-btn {

  &:focus,
  &:focus-within {
    border-color: #b6babd;
  }


  &:hover {
    background-color: var(--danger);
    border-color: var(--danger);
    color: white;
  }
}
