@import "../../../assets/styles/mixins";

.tv-channel-heading {
   text-align: center;
   color: var(--text-gray-color);

   @include media-breakpoint-up(md) {
      text-align: left;
   }
}

.tvSubscribeBtn {
   font-size: $font-size-base + 0.15;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding-left: 30px;
   padding-right: 30px;
   font-weight: 600;

   .try-rupee {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-left: 3px;

      svg {
         width: 16px !important;
         height: 16px !important;
         vertical-align: top;
      }
   }

   .try-rupee-text {
      margin-right: 4px;
   }
}

.channel-category-title {
   font-weight: 500;
   font-size: $font-size-base + 0.3;
   color: var(--text-color);
   border-bottom: 1px solid var(--border-color-light);
   padding-bottom: 5px;
   margin-bottom: 20px;
}

.subscribe-price {
   width: 100%;
   display: inline-flex;
   flex-direction: column;

   .subscribe-info {
      display: block;
      text-align: center;
      color: var(--text-gray-color);
      font-size: $font-size-base - 0.17;
      margin-top: 5px;
      font-weight: 500;
   }

   @include media-breakpoint-up(sm) {
      max-width: 200px;
      text-align: center;
      float: right;
   }
   @include media-breakpoint-up(md) {
      text-align: right;
   }
}

.category-channels-list {
   display: grid;
   grid-template-columns: calc(50% - 3px) calc(50% - 3px);
   column-gap: 5px;
   row-gap: 5px;

   @include media-breakpoint-up(sm) {
      grid-template-columns: calc(33.33% - 9px) calc(33.33% - 9px) calc(33.33% - 9px);
   }

   @include media-breakpoint-up(md) {
      grid-template-columns: calc(25% - 5px) calc(25% - 5px) calc(25% - 5px) calc(25% - 5px);
   }

   @include media-breakpoint-up(lg) {
      grid-template-columns: calc(20% - 5px) calc(20% - 5px) calc(20% - 5px) calc(20% - 5px) calc(20% - 5px);
   }

   @include media-breakpoint-up(xl) {
      grid-template-columns: calc(16.66% - 4px) calc(16.66% - 4px) calc(16.66% - 4px) calc(16.66% - 4px) calc(16.66% - 4px) calc(16.66% - 4px);
   }
}


.tabs-list-block {
   position: relative;
}

.tabs-list {
   display: flex;
   flex-direction: row;
   align-items: stretch;
   border-bottom: 1px solid var(--border-color-3);
   background-color: var(--bg);
   z-index: 7;
   position: sticky;
   top: 150px;
   margin-bottom: 20px;

   .each-tv-tab {
      float: left;
      width: 25%;
      border: none;
      padding: 10px 0px;
      color: var(--text-gray-color-2);
      border-bottom: 3px solid transparent;
      border-radius: 0;
      text-align: center;
      font-size: $font-size-base - 0.15;

      &.active {
         border-bottom-color: var(--primary);
         color: var(--text-color);
      }
   }

   @include media-breakpoint-up(md) {
      .each-tv-tab {
         font-size: $font-size-base;
      }
   }

}

.buy-tv-package {
   font-size: $font-size-base;
   background-color: var(--primary);
   color: white;
   margin-left: 15px;
   float: right;

   svg {
      width: 15px !important;
      height: 15px !important;
   }

   &:hover {
      color: white;
   }
}

.channel-price {
   position: absolute;
   right: 6px;
   top: 6px;
   z-index: 8;
   background-color: var(--bg-2);
   line-height: 1;
   padding: 0 5px;
   height: 20px;
   border-radius: 100px;
   color: white;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: $font-size-base - 0.2;
   font-weight: 700;
   letter-spacing: 1px;

   svg {
      width: 13px !important;
      height:  13px !important;
      margin-top: -1px;
   }
}