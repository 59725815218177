@import "../../../assets/styles/mixins";


.subscribe-title {
  font-size: $font-size-base + 0.2;
}

.each-subscription-column {
  position: relative;

  color: var(--text-color);


  .each-subscription-column-title {
    font-size: $font-size-base - 0.09;
    color: var(--text-color);
    text-align: center;
    font-weight: 700;
  }

  .each-subscription-price {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    line-height: 1;
  }

  .rupee-currency {
    color: var(--text-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    line-height: 1;
    padding-top: 12px;
    padding-right: 4px;
  }

  .price-text {
    display: inline-flex;
    font-size: $font-size-base + 1.5;
    color: var(--text-color);
    letter-spacing: 2px;
    line-height: 1.1;
  }

  .each-subscription-benefits-list {
    li {
      float: left;
      width: 100%;
      text-align: center;
      padding-bottom: 12px;
      padding-top: 12px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 0;
        height: 1px;
        background-color: var(--bg);
        filter: brightness(1.8);
        border-radius: 10px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .each-subscription-action {
      padding-top: 10px;
    }

  .buy-subscription-btn {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: $font-size-base + 0.3;
    line-height: 1.1;
  }
}

.subscription-radio {
  cursor: pointer;

  .subscription-details {
    position: relative;
    padding: 28px 20px;
    margin-bottom: 20px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      background-color: var(--bg-2);
      border: 1px solid var(--border-color-2);
      z-index: 1;
    }

    /* &::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 12px;
      border-right: 3px solid var(--border-color-light);
      border-top: 3px solid var(--border-color-light);
      filter: brightness(1.9);
      transition: all 0.25s ease;
      transform: rotate(-200deg);
      opacity: 0;
      z-index: 2;
    } */

    .selected-icon-block {
    position: absolute;
    right: 1px;
    top: 3px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    display: none;

    svg {
      width: 36px;
      height: 36px;
      fill: var(--primary);
    }
  }
  }



  .radio-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;

    &:checked + .subscription-details {
      &::before {
        /* filter: brightness(0.5); */
        border-color: var(--border-color-light);
      }

      /* &::after {
        transform: rotate(-225deg);
        opacity: 1;
      } */

      .selected-icon-block {
        display: block;
      }
    }
  }

  .subscription-details-block {
    position: relative;
    z-index: 4;
  }

  @include media-breakpoint-up(md) {
    .subscription-details {
      margin-bottom: 0;
    }
  }
}
