

input,
button,
select,
a {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent;
  }
}

.full-width {
  float: left;
  width: 100%;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
}

.page {
  padding-top: $headerHeight;
  /* padding-top: 10px; */
  color: var(--text-color);
  font-size: $font-size-base;
  min-height: calc(100vh - #{$headerHeight});
  background-color: var(--bg);

  @include media-breakpoint-up(xl) {
    /* padding-left: 50px;
    padding-right: 50px; */
  }
}

.playlist-heading {
  font-size: $font-size-base + 0.1;
  margin: 0;
  padding: 0 0 10px 0;
  font-weight: 500;
  color: var(--text-gray-color);

  .playlist-heading-title {
    float: left;
    color: var(--text-color);
    max-width: calc(100% - 50px);
    text-decoration: none;
    font-size: $font-size-base + 0.2;
  }

  a.playlist-heading-title {
    &:hover {
      text-decoration: none;
      color: var(--blue);
    }
  }

  .playListMoreBtn {
    float: right;
    font-size: $font-size-base;
    color: var(--blue);
    width: 50px;
    padding-top: 5px;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: $font-size-base + 0.6;
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base + 1;
  }
}

.text-truncate {
  @include text-truncate();
}

.meta-list {
  display: flex;
  @include text-truncate;
  align-items: center;
  flex-wrap: nowrap;

  .each-meta-item {
    display: inline-flex;
    align-items: center;
    font-size: $font-size-base - 0.1;
    padding-left: 10px;
    padding-right: 7px;
    color: $gray;
    position: relative;
    -webkit-font-smoothing: antialiased;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      width: 3px;
      height: 3px;
      border-radius: 10px;
      background-color: var(--gray);
    }

    &:first-child {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

.min-height {
  min-height: calc(100vh - 150px);
  padding-bottom: 50px;

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - 200px);
  }
}

.password-input-block {
  position: relative;
  display: flex;
  align-items: center;

  .password-input {
    float: left;
    width: calc(100% - 40px);
  }

  .passwordViewToggle {
    border: none;
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.ml-05 {
  margin-left: 5px;
}

.mr-05 {
  margin-right: 5px;
}

.mt-05 {
  margin-top: 5px;
}

.mb-05 {
  margin-bottom: 5px;
}

input,
button,
select {
  appearance: unset;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, #ccc 50%),
    linear-gradient(135deg, #ccc 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 0em;
  background-repeat: no-repeat;
}

.heading-xxl {
  font-size: $font-size-base + 1;
}

.movie-model {
  font-size: $font-size-base - 0.26;
  font-family: $font-family-base-2;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  display: inline-block;
  padding: 3px 10px;
  line-height: 1.2;
  border-radius: 3px;
  letter-spacing: 1px;

  &.free {
    background-color: var(--yellow);
    color: var(--dark);
  }

  &.premium {
    background-color: var(--primary);
    color: white;
  }

  &.ppv {
    background-color: var(--blue);
    color: white;
  }
}

.dot {
  padding-left: 15px;
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
    display: block;
    background-color: var(--gray);
    border-radius: 100px;
  }

  &:first-child {
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

.change-theme-block {
  /* background-color: rgba(10,10,10,0.8); */
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .theme-radio {
    width: 24px;
    height: 24px;
    border: 2px solid #bbb;
    border-radius: 100px;
    margin-bottom: 0;
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .radio-text {
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 100px;
      background-color: var(--bg);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        width: 12px;
        height: 7px;
        border-right: 2px solid white;
        border-top: 2px solid white;
        transform: rotate(0) scale(0.5);
        opacity: 0;
        transition: all 0.2s ease;
      }
    }

    .radio-input {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;

      &:checked + .radio-text {
        &::after {
          transform: rotate(135deg) scale(1);
          opacity: 1;
        }
      }
    }

    &.theme-1 {
      .radio-text {
        background-color: rgb(58, 58, 60);
      }
    }

    &.theme-2 {
      .radio-text {
        background-color: rgb(44, 44, 46);
      }
    }

    &.theme-3 {
      .radio-text {
        background-color: rgb(28, 28, 30);
      }
    }
  }
}

.pleaseLoginBlock {
  min-height: 400px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 25px;
  font-size: $font-size-base + 1;
  flex-wrap: wrap;

  .loginNowBtn {
    color: var(--text-color);
    text-decoration: underline;
    cursor: pointer;
    font-size: $font-size-base + 1;

    &:hover {
      color: var(--warning);
      text-decoration: none;
    }
  }
}

.switch {
  display: inline-block;
  position: relative;

  .switch-content {
    display: inline-block;
    padding-left: 62px;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      width: 46px;
      height: 26px;
      background-color: var(--text-color);
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      border-radius: 100px;
      z-index: 2;
    }

    &::after {
      content: "";
      width: 22px;
      height: 22px;
      border-radius: 100px;
      background-color: var(--text-gray-color-2);
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: 4;
      transition: all 0.3s ease;
    }
  }

  .switch-input {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:checked + .switch-content {
      &::after {
        background-color: var(--success);
        left: 22px;
        transition: all 0.3s ease;
      }
    }

    &:disabled + .switch-content {
      &::after {
        opacity: 0.7;
      }
    }
  }
}

.page-heading {
  font-size: $font-size-base + 1;
}

.gr-1 {
  background-color: #85ffbd;
  background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
}

.gr-2 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}

.gr-3 {
  background-color: #00dbde;
  background-image: linear-gradient(90deg, #00dbde 0%, #fc00ff 100%);
}

.gr-4 {
  background-color: #fa8bff;
  background-image: linear-gradient(
    45deg,
    #fa8bff 0%,
    #2bd2ff 52%,
    #2bff88 90%
  );
}

.gr-5 {
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
}

.gr-6 {
  background-color: #52acff;
  background-image: linear-gradient(180deg, #52acff 25%, #ffe32c 100%);
}

.gr-7 {
  background-color: #a9c9ff;
  background-image: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%);
}

.gr-8 {
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
}

.gr-9 {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.profile-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--secondary);

  &.pf-1 {
    background-image: url(../images/bg-1.jpg);
  }

  &.pf-2 {
    background-image: url(../images/bg-2.jpg);
  }

  &.pf-3 {
    background-image: url(../images/bg-3.jpg);
  }

  &.pf-4 {
    background-image: url(../images/bg-4.jpg);
  }
}

.pf-1 {
}