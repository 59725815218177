// Dark Theme Color Combo = https://color.adobe.com/search?q=dark%20theme

/* @import "./assets/styles/bootstrap/functions";
@import "./assets/styles/bootstrap/variables";
@import "./assets/styles/bootstrap/mixins"; */

@import "./assets/styles/mixins";

input,
select,
button {
   &:focus,
   &:focus-within {
      box-shadow:  0 0 0 transparent;
      outline: none;
      outline-color: transparent;
   }
}


@import "./assets/styles/common";
@import "./assets/styles/theme";



@import "./assets/styles/telugu";