.slick-slider {
  float: left;
  width: 100%;
  position: relative;
  /* padding-left: 10px;
  padding-right: 10px; */

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;



  .slick-list,
  .slick-slide {
    float: left;
    width: 100%;
  }

  .slick-slide {
    > div {
      float: left;
      width: 100%;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    z-index: 6;
    font-size: 0;
    background-color: transparent;
    /* border-radius: 100px; */
    margin-top: -20px;
    border: none;
    /* display: none !important; */
    align-items: center;
    justify-content: center;
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      border-top: 3px solid var(--gray);
      border-right: 3px solid var(--gray);
      transform: rotate(45deg);
    }

    /* &:hover {
      background-color: transparent;
    } */

    &.slick-next {
      right: 0px;

      &::before {
        left: 10px;
      }
    }

    &.slick-prev {
      left: 0px;
      &::before {
        transform: rotate(-135deg);
        left: 15px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;

    /* &:hover {
      .slick-arrow {
        display: flex !important;
        background-color: var(--secondary);
      }
    } */
  }
}
