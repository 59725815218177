@import "../../../assets/styles/mixins";

html,
body {
  overflow-x: hidden;
}

.header {
  background-color: var(--bg);
  color: var(--light-text-color);
  padding-top: 5px;
  padding-bottom: 5px;
  height: $headerHeight;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 19;
  border-bottom: 1px solid var(--border-color);

  .branding {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .mobile-menu-toggle-btn {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: $headerHeight - 15;
  }

  .branding-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0;
    margin-left: 10px;

    img {
      max-width: 70px;
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    .branding {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  @include media-breakpoint-up(lg) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  @include media-breakpoint-up(xl) {
    .container-fluid {
      padding-left: 50px;
      padding-right: 50px;
    }

    .branding-link {
      padding-left: 0;
    }
  }
}

.main-nav-block {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  transform: translateX(-250%);
  transition: all 0.3s ease;

  &.show-menu {
    transform: translateX(0);
  }

  .mobile-nav-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
  }

  .main-nav-list-block {
    position: relative;
    height: 100%;
    overflow-y: auto;
    /* background-color: var(--secondary); */
    background-color: var(--bg);
    z-index: 4;
    width: 100%;
  }

  .main-nav-list {
    padding: 0 0 20px 0;

    li {
      float: left;
      width: 100%;
    }

    a {
      color: var(--text-gray-color);
      padding: 6px 25px;
      display: flex;
      border-radius: 5px;
      font-weight: 600;
      font-size: $font-size-base + 0.5;

      &:hover {
        text-decoration: none;
        color: var(--text-white);
        /* color: var(--dark); */
      }

      &.activeLink {
        color: var(--primary);
      }
    }
    .mobileSubscribe {
      background-color: var(--tertiary);
      font-size: $font-size-base + 0.1;
      font-weight: bold;
      line-height: 1.1;
      border-radius: 0;
      text-align: center;
      color: white;
      justify-content: center;
      padding-top: 12px;
      padding-bottom: 12px;

      .try-rupee {
        svg {
          width: 15px !important;
          height: 15px !important;
        }
      }

      &.activeLink {
        color: white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    right: auto;
    transform: translateX(0);
    box-shadow: none;

    .main-nav-list-block {
      box-shadow: none;
      background-color: transparent;
      overflow: visible;
    }

    .main-nav-list {
      padding: 0;
      display: flex;
      align-items: center;

      li {
        width: auto;
      }

      a {
        padding: 6px 8px;
        font-size: $font-size-base - 0.07;
        text-transform: uppercase;

        &:hover {
          color: var(--text-color);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 4px;
  }

  @include media-breakpoint-up(xl) {
    .main-nav-list {
      a {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.closeMobileNavBtn {
  color: white;
  cursor: pointer;
  padding-top: 17px;
  padding-bottom: 17px;

  svg {
    color: var(--text-color) !important;
    width: 32px !important;
    height: 32px !important;
  }
}

.header-right-actions-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  /* padding-top: 6px;
  padding-bottom: 6px; */

  .headerSubscribeBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    background-color: transparent;
    color: var(--text-gray-color);
    border-radius: 3px;
    border: 1px solid var(--border-color-light);
    font-size: $font-size-base - 0.15;
    text-transform: uppercase;
    height: 32px;
    font-weight: 500;
    line-height: 1;

    &:hover {
      text-decoration: none;
      background-color: var(--primary);
      color: white;
      border-color: var(--primary);
    }

    .try-rupee {
      width: 12px;
      display: inline-block;
      margin-left: 4px;
      opacity: 0.7;

      svg {
        width: 13px !important;
        height: 13px !important;
      }
    }

    .try-rupee-text {
      margin-right: 4px;
      font-size: $font-size-base - 0.1;
      line-height: 1;
    }
  }

  .headerLoginBtn {
    /* margin-left: 10px; */
    height: 30px;
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    /* padding-right: 10px; */
    padding-right: 0;
    color: var(--text-gray-color);
    font-size: $font-size-base - 0.1;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      color: var(--text-color);
    }

    &.activeLink {
      color: white;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: -1px;
    .headerSubscribeBtn {
      font-size: $font-size-base - 0.2;
      padding-left: 7px;
      padding-right: 7px;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 7px;
    }

    .headerLoginBtn {
      padding-left: 0px;
      /* padding-right: 5px; */
    }
  }

  @media screen and (min-width: 800px) {
    margin-top: 1px;
    .headerSubscribeBtn {
      font-size: $font-size-base - 0.2;
      padding-left: 12px;
      padding-right: 12px;
    }

    .headerLoginBtn {
      /* padding-left: 10px; */
      /* padding-right: 10px; */
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 1px;
  }
}

.mobileHeaderSearchBtn {
  /* margin-right: 5px; */
  z-index: 6;
  padding-left: 10px;
  padding-right: 10px;

  .searchIcon {
    display: block;
    color: var(--text-gray-color);
  }

  .searchCloseIcon {
    display: none;
    color: var(--text-color);
  }

  &.mobile-search-active {
    .searchIcon {
      display: none;
    }
    .searchCloseIcon {
      display: block;
    }
  }

  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }

  @include media-breakpoint-up(md) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.header-search-block {
  position: fixed;
  left: 0;
  top: $headerHeight - 5;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px; */
  transform: translateX(-150%);
  transition: all 0.3s ease;
  opacity: 0;
  background-color: var(--bg);
  /* box-shadow: 0 3px 5px var(--dark); */

  &.show-mobile-search {
    transform: translateX(0);
    opacity: 1;
  }

  .searchInput {
    background-color: var(--bg);
    border-color: transparent;
    border-bottom: 1px solid var(--border-color-light);
    color: var(--text-gray-color);
    float: left;
    width: 100%;
    padding-right: 30px;
    height: 38px;
  }

  .headerSearchBtn {
    position: absolute;
    right: 5px;
    top: 0px;
    padding: 0;
    opacity: 0.7;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 70px;
    top: 0;
    z-index: 4;
    width: 0;
    max-width: calc(100% - 225px);
    padding: 0;
    transform: translateX(0);
    opacity: 1;
    box-shadow: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);

    &.show-mobile-search {
      width: 100%;
    }

    .headerSearchBtn {
      right: 5px;
    }

    &.before-login {
      max-width: calc(100% - 190px);
    }
  }
}

.header-my-account-toggle-btn {
  color: var(--text-gray-color);
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--text-gray-color-2);
  }
}

.header-my-account {
  position: relative;

  .my-account-list {
    position: absolute;
    right: -5px;
    top: 40px;
    background-color: var(--bg);
    width: 180px;
    padding-top: 10px;
    border-radius: 5px;
    filter: brightness(1.5);

    &::before {
      content: "";
      position: absolute;
      right: 28px;
      top: 0px;
      border: 8px solid transparent;
      border-top-color: var(--bg);
      filter: brightness(0.6);
      display: block;
    }
  }

  .my-account-item-link {
    color: var(--text-gray-color);
    display: block;
    padding: 7px 15px;
    width: 100%;
    text-align: left;
    position: relative;

    &:hover {
      background-color: var(--bg);
      filter: brightness(0.75);
      text-decoration: none;
      color: var(--text-color);
    }
  }

  .my-account-active-link {
    color: var(--text-color);
    /* background-color: var(--bg); */
    filter: brightness(1.5);
  }

  .profile-icon-name {
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-menu-profile-icon {
      width: 26px;
      height: 26px;
      float: left;
      border-radius: 300px;
      display: block;
    }

    .header-menu-profile-name {
      float: left;
      width: calc(100% - 30px);
      padding-left: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    .my-account-list {
      &::before {
        right: auto;
        left: 50%;
      }
    }
  }
}

.my-account-toggle-btn {
  display: flex;
  align-items: center;
  color: var(--text-color);
  padding: 5px 17px 5px 10px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 18px;
    border: 5px solid transparent;
    border-top-color: var(--border-color-light);
    transition: all 0.2s ease;
  }

  &:focus,
  &:focus-within,
  &:active,
  &:hover {
    outline: none;
    box-shadow: 0 0 0 transparent;
  }

  &.open-menu {
    &::after {
      transform: rotate(180deg);
      top: 12px;
    }
  }

  .profile-user-icon {
    width: 30px;
    height: 30px;
    fill: var(--text-gray-color-2);
  }

  .header-profile-icon {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    margin-right: 8px;
  }

  .profile-user-name {
    display: none;
    color: var(--text-gray-color);
    font-size: $font-size-base - 0.1;
  }

  @include media-breakpoint-up(lg) {
    .profile-user-name {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      max-width: 102px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.headerLoginBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-gray-color);

  &:hover {
    color: var(--text-color);
  }
}

.toggleLanguageBlock {
  display: inline-flex;
  height: 34px;
  position: relative;
  margin-right: 17px;

  .toggleLanguageBtn {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 2px 0 0 0;
    position: relative;

    svg {
      color: var(--text-gray-color);
    }

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      right: -10px;
      border: 5px solid transparent;
      border-top-color: var(--text-gray-color);
    }
  }

  .languages-list {
    position: absolute;
    right: -100px;
    top: 40px;
    width: 200px;
    color: var(--text-color);
    background-color: var(--bg-2);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    display: none;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.4);

    li {
      width: 100%;
      float: left;
      border-bottom: 1px solid var(--border-color-2);

      &:last-child {
        border-bottom-color: transparent;
      }
    }

    .each-language {
      padding: 0;
      float: left;
      width: 100%;
      padding: 7px 15px;
      text-align: left;
      color: var(--text-color);
      font-size: $font-size-base - 0.1;
    }
  }

  &.openLanguageMenu {
    .toggleLanguageBtn {
      svg {
        color: var(--text-color);
      }

      &::before {
        transform: rotate(180deg);
        top: 8px;
      }
    }

    .languages-list {
      display: block;
    }

    .each-language {
      &:hover {
        background-color: var(--dark);
      }
    }
  }

  @include media-breakpoint-up(md) {
    /* margin-left: 5px; */
    margin-right: 30px;
  }
}

.sep-div {
  height: 1px;
  border-bottom: 1px solid var(--border-color-2);
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}
