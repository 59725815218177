@import url('https://fonts.googleapis.com/css2?family=Material+Icons');
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/videojs-resolution-switcher";

.video-player-outer-block {
  /* position: relative;
  height: 100%; */
}

$controlBar: 40px;

.rvr-player {
  width: 100%;
  background-color: black;
  height: calc(100vh - 150px);

  .vjs-poster {
    background-color: black;
    background-position: center;
    background-size: 50% auto;
  }

  .vjs-button {
    > .vjs-icon-placeholder{
      &::before {
        line-height: $controlBar;
      }
    }
  }

  // Time stamp
  .vjs-remaining-time,
  .vjs-duration-display {
    font-size: 11px !important  ;
    line-height: 36px;
  }

  .vjs-remaining-time {
    width: 50px;
    position: absolute;
    top: -30px;
    right: 7px;
  }

  // Control bar
    .vjs-control-bar {
      height: $controlBar;
      background-color: transparent;
    }

  // Control Icon size
    .vjs-control {
      font-size: 14px;
      width: 40px;
    }

  // Progress bar
  .vjs-progress-control {
    position: absolute;
    top: -22px;
    left: 5px;
    width: calc(100% - 52px);
    height: 20px;
  }

  .vjs-play-progress {
    background-color: var(--primary);
    &::before {
      font-size: 14px;
      color: var(--primary);
    }
  }

  // Rightside icons
  .vjs-fullscreen-control {
    position: absolute;
    right: 5px;
  }

  //Picture in Picture
  .vjs-picture-in-picture-control {
    display: none;
  }

  // Hiding Volume Percentage
  .vjs-volume-tooltip {
    display: none;
  }

  // Back 10 seconds Button
  .back10-btn {
    font-family: 'Material Icons';
    position: relative;

    &::before {
      content: "replay_10";
      font-family: 'Material Icons';
      font-size: 24px;
    }
  }

  // Forward 10 seconds Button
  .forward10-btn {
    font-family: 'Material Icons';
    position: relative;

    &::before {
      content: "forward_10";
      font-family: 'Material Icons';
      font-size: 24px;
    }
  }

  // Playlist button
  .playlist-btn {
    font-family: 'Material Icons';
    position: absolute;
    right: 40px;

    &::before {
      content: "playlist_play";
      font-family: 'Material Icons';
      font-size: 24px;
    }
  }

  // Video player button
  .vjs-big-play-button {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
    font-size: 50px;
    line-height: 94px;
    border-width: 2px;
  }

  .video-title {
    width: calc(100% - 300px);
    color: white;
    font-size: 12px;
  }

}

.videoPlayerId-dimensions.vjs-fluid {
    padding-top: 100% !important;

  @media screen and (min-width: 768px) {
    padding-top: 70% !important;
  }

  @media screen and (min-width: 992px) {
    padding-top: 42% !important;
  }
}


.video-outer-block {
  position: relative;
  height: 100%;
}

.play-list-block {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 70px;
  background-color: rgba(0,0,0,0.75);
  max-width: 400px;
  border-radius: 5px;
  transform: translateX(250%);
  transition: all 0.3s ease-in-out;


  .play-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    position: relative;
    z-index: 4;

    li {
      float: left;
      width: 100%;
      border-bottom: 1px solid #555;

      &:last-child {
        border-bottom-color: transparent;
      }
    }
  }

    &.show-play-list {
    transform: translateX(0);
  }


  .play-list-item-btn {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: white;
    padding: 10px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .play-list-header {
    position: sticky;
    left: 0;
    top: 0;
    background-color: black;
    color: white;
    line-height: 1;
    padding: 5px 10px;
    display: flex;
    align-items: stretch;
    z-index: 2;
  }

  .play-list-header-title {
    float: left;
    width: calc(100% - 30px);
    font-size: 12px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .play-list-close-btn {
    background-color: transparent;
    color: white;
    font-size: 20px;
    float: right;
    border: none;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    width: 30px;
  }

  @media screen and (min-width: 768px) {
    left: auto;
  }

}
