@import "../../../assets/styles/mixins";

.user-info-block {
  display: flex;
  align-items: center;

  .user-info-icon {
    float: left;
    /* width: 80px; */
  }

  .user-name-phone-block {
    float: left;
    /* width: calc(100% - 80px); */
  }

  .user-name {
    color: var(--text-color);
  }

  .user-phone {
    font-size: $font-size-base - 0.1;
    font-family: var(--font-family-2);
    color: var(--text-gray-color);
  }

  .userNameBtn {
    color: var(--text-gray-color);
    text-align: left;
    font-size: $font-size-base + 0.7;
    font-weight: 400;
    padding: 0;
    line-height: 1.2;

    .edit-user-name {
      /* border: 1px solid #666; */
      width: 25px;
      height: 25px;
      padding: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
    }

    &:hover {
      .edit-user-name {
        background-color: var(--secondary);
      }
    }
  }
}

.subscribe-now-block {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background-color: var(--bg);
    filter: brightness(0.85);
  }
}

.subscribe-now-content {
  position: relative;
  z-index: 3;
  .subscribe-now-content-title {
    color: var(--text-color);
    font-size: $font-size-base + 0.4;
    text-align: center;
    padding-bottom: 7px;
  }

  .subscribe-now-btn {
    background-color: var(--primary);
    color: white;
    width: 100%;
    text-decoration: none;
    border: none;
    display: block;
    text-align: center;
    line-height: 1;
    padding: 13px 20px;
    border-radius: 5px;
    font-size: $font-size-base + 0.2;

    &:hover {
      text-decoration: none;
      opacity: 0.9;
    }
  }
}

.transactions-toggle-btn {
  padding: 10px 20px;
  font-size: $font-size-base + 0.2;
  border-color: var(--border-gray-2);
  background-color: var(--bg);
  position: relative;
  color: var(--text-gray-color);

  svg {
    color: var(--text-gray-color) !important;
    fill: var(--text-gray-color) !important;
  }

  &:hover {
    color: var(--text-color);
    filter: brightness(0.8);
    border-color: var(--border-color-2);

    &.logout-btn {
      background-color: var(--danger);
      filter: brightness(1);
      border-color: var(--danger);
    }

    svg {
      color: var(--text-color) !important;
      fill: var(--text-color) !important;
    }
  }
}

.my-profile-new {
  .profile-photo-block {
    float: left;
    width: 100%;
  }

  .profile-user-icon {
    width: 130px;
    height: 130px;
    border-radius: 100px;
    fill: white;
    opacity: 0.9;
  }

  .profile-details-info {
    float: left;
    width: 100%;
   padding-top: 10px;
  }
  .profile-name {
     font-size: $font-size-base + 0.8;
     font-weight: 600;
  }

  .profile-phone-mobile {
     font-size: $font-size-base + 0.15;
     color: var(--text-gray-color);
  }

  .each-row {
   margin-top: 20px;
   padding-top: 20px;
   border-top: 1px solid var(--border-color-2);
  }

  .each-row-label {
     font-size: $font-size-base;
     padding-bottom: 7px;
     color: var(--text-gray-color);

     small {
       font-size: 90%;
     }

     &.other-info {
       padding-top: 7px;
       font-size: 95%;
       color: var(--text-gray-color);
     }
  }

  @include media-breakpoint-up(md) {
     .profile-photo-block {
      width: 130px;
     }

     .profile-details-info {
        width: calc(100% - 130px);
        padding-top: 0;
        padding-left: 30px;
     }
  }
}


.photo-design-block {
  width: 130px;
  height: 130px;
  display: block;
  border-radius: 250px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.edit-profile-child-control {
  padding-top: 7px;
  padding-bottom: 7px;

  .edit-profile-child {
    border: 1px solid var(--border-color-2);
    padding-left: 30px;
    padding-right: 35px;
    color: var(--text-gray-color-2);
  }
}