@import "../../../assets/styles/mixins";

.login-input {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  padding-left: 3px;
  font-size: $font-size-base;
  height: 45px;

  &:focus,
  &:hover,
  &:active {
    border: none;
    background-color: transparent;
    color: var(--text-color);
    box-shadow: 0 0 0 transparent;
  }
}

.loginHeading {
  color: var(--text-gray-color);
  font-size: $font-size-base + 0.5;
  font-weight: 600;

  &.success-logedin {
    color: var(--success);
    filter: brightness(1.8);
  }
}

.success-sub-info {
  color: var(--text-gray-color);
}

.login-back-btn {
  color: var(--blue);
  padding: 0;
  display: inline-flex;
  align-items: center;

  span {
    height: 18px;
    display: inline-flex;
    align-items: center;
  }

  svg {
    height: 18px !important;
  }

  &:hover {
    color: var(--text-gray-color);
    text-decoration: none;
  }
}

.loginBg {
}

.toggle-other-login-methods-btn {
  border: 1px solid var(--border-color-2);
  /* background-color: var(--secondary); */
  background-color: transparent;
  padding: 10px 20px;
  text-align: left;
  color: var(--text-gray-color);
  font-size: $font-size-base + 0.1;
  width: 100%;
  /* max-width: 340px; */

  .toggle-login-method-icon {
    display: inline-block;
    margin-right: 20px;

    svg {
      fill: var(--text-color);
      color: var(--text-color);
    }
  }

  &:hover {
    color: var(--text-color);

    &.facebook {
      background-color: var(--facebook);
      color: white;
      border-color: var(--facebook);

      svg {
        fill: white;
      }
    }

    &.google {
      background-color: var(--google);
      color: var(--text-color);
      border-color: var(--google);

      svg {
        fill: var(--text-color);
      }
    }

    &.email {
      background-color: var(--bg);
      color: var(--text-color);
      border-color: var(--border-color-light);

      svg {
        fill: var(--text-color);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.login-form {
  /* width: 390px; */
}

.phone-country-block {
  border-bottom: 1px solid var(--border-color-light);

  .phone-country-code {
    border: none;
    height: 40px;
    background-color: transparent;
    background-repeat: no-repeat;
    color: var(--text-color);
    font-size: $font-size-base;
    padding: 0 8px;
    width: 60px !important;
    padding-left: 0;
    float: left;
    letter-spacing: 2px;
  }

  .phone-input {
    background-color: transparent;
    border: none;
    color: var(--text-color);
    font-size: $font-size-base + 0.3;
    float: left;
    width: calc(100% - 60px);
    letter-spacing: 2px;
    height: 45px;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border: none;
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      font-size: $font-size-base + 0.1;
      letter-spacing: 0px;
      color: #aaa;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: $font-size-base + 0.1;
      letter-spacing: 0px;
      color: #aaa;
    }

    &::placeholder {
      font-size: $font-size-base + 0.1;
      letter-spacing: 0px;
      color: #aaa;
    }
  }
}

.form-error {
  color: #ffb74d;
  padding-top: 5px;
}

.submit-phone-btn {
  font-size: $font-size-base + 0.1;
}

.otp-title {
  color: var(--text-gray-color);
  font-size: $font-size-base + 0.5;
}

.otp-block {
  max-width: 250px;
  border-bottom: 1px solid var(--border-color-light);
  clear: both;
  text-align: center;
}

.otp-input {
  font-size: $font-size-base + 1.5;
  letter-spacing: 15px;
  text-align: left;
  height: 60px;


  &:focus {
    background-color: transparent;
    color: var(--text-color);
  }

  &::-webkit-input-placeholder {
    /* Edge */
    font-size: $font-size-base + 1.5;
    letter-spacing: 10px;
    text-align: left;
    color: #ccc;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: $font-size-base + 1.5;
    letter-spacing: 10px;
    text-align: left;
    color: #ccc;
  }

  &::placeholder {
    font-size: $font-size-base + 1.5;
    letter-spacing: 10px;
    text-align: left;
    color: #ccc;
  }
}

.resend-btn {
  background-color: transparent;
  color: var(--dark);
  line-height: 1.2;
  margin-top: 5px;

  &:disabled,
  &.disabled {
    background-color: var(--secondary);
    color: $gray;
  }
}

.social-logins-list {
  li {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.social-login {
  width: 320px;
  background-color: var(--dark);
  height: 44px;
  color: var(--dark);
  line-height: 1.1;
  font-size: $font-size-base + 0.1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;

  .social-login-icon {
    margin-right: 15px;
  }

  &:hover {
    background-color: #000;
    color: var(--dark);
  }

  /* &.facebook {
      background-color: #000;
   }

   &.google {
      background-color: var(--gray);
      color: #333;
   } */
}

.thanks-message {
  padding: 40px 25px;

  .thanks-title {
    font-size: $font-size-base + 0.6;
    font-weight: 700;
    color: var(--text-color);
    line-height: 1.1;
  }

  .thanks-info {
    color: var(--text-color);
    font-size: $font-size-base + 0.2;
  }
}

.login-outer-block {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 20;
  color: var(--text-color);

  .login-outer-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .login-form-outer-block {
    position: relative;
    width: 100%;

    z-index: 4;
  }

  .login-block {
    width: 100%;
  }

  .login-main {
    float: left;
    width: 100%;
    min-height: 100vh;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--bg);
      filter: brightness(1.5);
    }
  }

  .login-main-block {
    position: relative;
    z-index: 4;
  }

  .login-form-header {
    border-bottom: 1px solid var(--border-color-3);
    padding: 15px 60px 15px 20px;
    position: relative;
    /* position: sticky;
    top: 0; */
  }

  .login-close-btn {
    position: absolute;
    right: 10px;
    top: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;

    svg {
      width: 32px !important;
      height: 32px !important;
      color: var(--text-color);
    }
  }

  .login-title {
    text-align: left;
    font-size: $font-size-base + 0.3;
    color: var(--text-color);

    .number {
      display: none;
    }
  }

  .login-form-content {
    /* padding: 20px 30px; */
    color: var(--text-gray-color);
    font-size: $font-size-base - 0.1;
  }

  .login-form-block {
    padding: 20px 30px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 30px;

    .login-block {
      min-height: inherit;
      max-width: 460px;
      margin: 0px auto;
    }

    /* .login-form-content {
      padding: 20px 40px;
    } */

    .login-form-block {
      padding: 20px 20px;
    }

    .login-main {
      min-height: inherit;
      border-radius: 5px;
    }
  }

  @include media-breakpoint-up(lg) {
    .login-form-header {
      position: relative;
    }
  }
}

input:-internal-autofill-selected {
  background: none !important;
}

.form-list {
  li {
    float: left;
    width: 100%;
  }
}

.otpError {
  margin-top: 5px;
}

.login-subscribe-container,
.login-main-block,
.login-billing-block {
  position: relative;
  z-index: 8;
}

/* .login-subscribe-container {
  position: relative;
  z-index: 8;
}
.login-main-block {
  position: relative;
  z-index: 6;
}
.login-billing-block {
  position: relative;
  z-index: 4;
} */

.subscribeBlock {
  .login-main-block {
    border-top: 1px solid var(--border-color-3);
    border-bottom: 1px solid var(--border-color-3);
    .login-close-btn {
      display: none;
    }
  }

  .pop-packages-block {
    padding: 10px 15px 10px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .login-form-header {
    background-color: var(--bg-2);
    /* position: sticky;
    z-index: 8;
    top: 0; */
    position: relative;
    padding-top: 10px;
    padding-bottom: 0px;
    border-bottom-color: transparent;

    &.fixPlans {
      position: fixed;
      top: 0;
    }

    &.fixLogin {
      position: fixed;
      top: 52px;
    }

    &.fixBilling {
      position: fixed;
      top: 104px;
    }

    /* &.plans-header {
      position: fixed;
      top: 0;
    }

    &.login-header {
      top: 52px;
    }

    &.confirm-pay {
      top: 104px;
    } */
  }

  .login-subscribe-container {
    .login-close-btn {
      top: 8px;
    }
  }

  .subscription-radio {
    width: 47%;

    .subscription-details {
      padding-top: 10px;
      padding-bottom: 0px;
    }

    .each-subscription-column-title {
      font-size: $font-size-base + 0.1;
      font-weight: 700;
      line-height: 1;
    }

    .each-subscription-price {
      display: inline-flex;
      align-items: center;
    }

    .price-text {
      font-size: $font-size-base + 0.8;
      font-weight: 700;
    }
  }

  .login-title {
    .number {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bg);
      width: 22px;
      height: 22px;
      border-radius: 100px;
      line-height: 1;
      font-size: $font-size-base - 0.1;
      margin-right: 5px;
      transform: translateY(-2px);
    }
  }

  .hasPromoCodeBlock {
    margin-bottom: 10px;
  }

  .hasPromoCodeText {
    color: white;
    font-size: $font-size-base;
  }

  .togglePromoCodeBtn {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary);

    &:hover {
      text-decoration: none;
    }
  }

  .pop-billing-info-block {
    padding: 10px 24px;
  }

  .pop-billing-list {
    li {
      float: left;
      width: 100%;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }
  }

  .pop-billing-item {
    display: flex;
    align-items: flex-start;

    .pop-billing-item-title {
      float: left;
      width: calc(100% - 80px);
      text-align: left;
    }

    .pop-billing-item-value {
      float: right;
      width: 80px;
      text-align: right;

      .rupee {
        svg {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }
  }

  .confirm-pay-btn {
    width: 100%;
    font-size: $font-size-base + 0.3;
  }

  .pop-billing-unselected {
    padding: 40px 20px;
    text-align: center;
    font-size: $font-size-base + 0.2;
    color: var(--text-gray-color);
  }

  .subscribe-name-logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-left: -10px;
    margin-top: -10px; */
    padding: 10px 20px;
    margin-bottom: 10px;
  }

  .name-block {
    float: left;
    width: calc(100% - 80px);
    font-size: $font-size-base + 0.2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .subscribe-logout-btn {
    float: right;
    width: 80px;
    color: var(--primary);
    text-decoration: none;
    font-size: $font-size-base - 0.1;
    padding: 0;
    /* margin-right: -10px; */
  }

  .discount-form-block {
    display: flex;
    flex-direction: row;
    padding: 0px 15px;
  }

  .discount-input {
    width: calc(100% - 150px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .apply-discount-btn {
    width: 170px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .toggleHavePromoCode-btn {
    width: 50px;
    padding-left: 15px;

    .toggleHavePromoIcon {
    }
  }

  .applied-promo-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .success-promo-message {
    float: left;
    width: 170px;
    color: #17b978;
    font-size: $font-size-base - 0.05;
  }

  .applied-code-block {
    float:left;
    width: calc(100% - 180px);
    margin-left: 10px;
  }

  .appliedCode {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 30px
  }

  .appliedCode {
    font-size: $font-size-base + 0.1;
    color: var(--text-color);
    display: inline-flex;

  }

  .removePromoCode {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    height: 30px;
    width: 30px;
    background-color: var(--bg);
    margin-left: 15px;
  }

  .deletePromoIcon {
    width: 22px;
    height: 22px;
  }

  .discount-error {
    padding: 5px 15px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px;
  }
}


.ppv-block {
  padding: 10px 20px;
  position: relative;

  .ppv-heading-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 15px;
  }

  .heading-text {
    float: left;
    width: calc(100% - 50px);
    font-size: $font-size-base + 0.3;

    .number {
          display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg);
    width: 22px;
    height: 22px;
    border-radius: 100px;
    line-height: 1;
    font-size: 0.9rem;
    margin-right: 5px;
    transform: translateY(-2px);
    }
  }

  .ppv-close-btn {
    float: right;
    width: 40px;
  }

  .ppv-image {
    width: 130px;
    float: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);

    img {
      width: 100%;
    }

  }

  .ppv-title-info {
    float: left;
    width: calc(100% - 130px);
    padding-left: 20px;
  }

  .ppv-title {
    float: left;
    font-size: $font-size-base + 0.5;
  }

  .ppv-duration {
    font-size: $font-size-base;
    color: var(--text-gray-color);
    padding-top: 8px;
  }

}