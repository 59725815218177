@import "../../../assets/styles/mixins";


.channel-item {
   float: left;
   width: 100%;
   position: relative;
   background-color: var(--white);
   overflow: hidden;
   border-radius: var(--border-radius);
   box-shadow: var(--box-shadow);
   transition: all 0.2s ease-in;

   .channel-item-thumbnail-block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      position: relative;
      z-index: 2;
      height: 120px;
   }

   .channel-item-thumbnail {
      max-width: 100%;
      max-height: 100%;
      position: relative;
      transition: all 0.3s ease-in;
   }

   .channel-play-btn {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 4;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      .playIcon {
         display: none;
      }
   }

   .channel-add-wishlist-btn {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 6;
      display: none;

   }

   @include media-breakpoint-up(lg) {

      .channel-play-btn {
         .playIcon {
            display: block;
            transform: scale(0.8);
            transition: all 0.4s ease-in-out;
            opacity: 0;
         }
      }

      .channel-add-wishlist-btn {
         display: flex;
         transform: translateX(-120%);
         transition: all 0.2s ease-in;
      }

      &:hover {

         /* transform: translate(0px, -5px); */
         box-shadow: var(--box-shadow-hover);


         .channel-item-thumbnail {
            transform: scale(1.15);
         }

         .channel-play-btn {
            background-color: rgba(0,0,0,0.5);

            .playIcon {
               opacity: 1;
               transform: scale(1);
            }
         }

         .channel-add-wishlist-btn {
            transform: translateX(0);
         }
      }

   }

}