// scale3d(1.3, 1.3, 1) translate3d(11%, 0, 0) perspective(500px)
// scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px)

@import "../../../assets/styles/mixins";

.posterPotrait {
  position: relative;
  height: 100%;
  float: left;
  width: calc((100vw - 20px) / 3);
  min-width: calc((100vw - 20px) / 3);
  max-width: calc((100vw - 20px) / 3);
  border-radius: var(--border-radius);
  transition: all 0.3s ease;


  button,
  a {
    &:focus,
    &:focus-within,
    &:active {
      box-shadow: 0 0 0 transparent;
      outline: none;
    }
  }

  .posterPotraitLink {
    display: block;
    float: left;
    width: 100%;
    border-radius: var(--border-radius);
    position: relative;
    padding: 0;
    cursor: pointer;
  }

  .posterThumbnail {
    float: left;
    width: 100%;
    position: relative;
    z-index: 2;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    /* padding-bottom: calc(1.32821 * 100%); */
    padding-bottom: 147.06%;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--bg);
    border: none;
    box-shadow: var(--box-shadow);
    transition: all 0.2s ease;


    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      z-index: 2;
    }
  }

  .meta-list {
    .each-meta-item {
      font-family: $font-family-base-2;
      font-size: $font-size-base - 0.25;
      padding-left: 0;
      padding-right: 5px;
      font-weight: 600;

      &::before {
        display: none;
      }
      /* padding-left: 7px;

      &::before {
        width: 2px;
        height: 2px;
        top: 7px;
      } */

      &::after {
        content: ",";
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .posterInfo {
    display: none;
  }

  .poasterPlayBtn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,0.5); */
    background-color: transparent;
    border: none;
    border-radius: var(--border-radius);

    .playIcon {
      display: none;
    }
  }

  .addWishListBtn {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 32px;
    height: 32px;
    border: none;
    z-index: 6;
    color: white;
    display: none;
    cursor: pointer;
    padding-left: 4px;

    .addWishListTitle {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    width: calc((100vw - 60px) / 5);
    min-width: calc((100vw - 60px) / 5);
    max-width: calc((100vw - 60px) / 5);
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    min-width: inherit;
    max-width: inherit;

    .posterInfo {
      display: block;
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      color: var(--text-color);
      z-index: 4;
      float: left;
    }

    .posterInfoContent {
      float: left;
      width: 100%;
      cursor: pointer;
      padding: 10px 0 0 0;
      border-radius: 0;
    }

    .meta-list {
      color: var(--text-gray-color-2);

      .each-meta-item {
        color: var(--text-gray-color-2);
      }
    }

    .posterThumbnail {
      padding-bottom: 134%;

      img {
        transition: all 0.3s ease-in-out;
      }

    }

    .poasterPlayBtn {
      opacity: 0;
      background-color: transparent;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;


      .playIcon {
        transform: scale(0.5);
      transition: all 0.3s ease;
        display: block;

      }
    }

    .poster-title {
      font-family: $font-family-base-2;
      font-size: $font-size-base - 0.1;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: left;
      color: var(--text-color);
      filter: brightness(0.85);
    }

    .poster-desc {
      font-size: $font-size-base - 0.17;
      max-height: 28px;
      overflow: hidden;
      -webkit-font-smoothing: antialiased;
      line-height: 1.1;
      margin-top: 4px;
      margin-bottom: 4px;
      text-align: left;
    }

    .addWishListBtn {
      display: block;
      transform: translateX(-130%);
      transition: all 0.2s ease-in;
      width: 32px;
      text-align: left;

      .addWishListTitle {
        opacity: 0;
      }

      /* background-color: var(--bg);
      width: 130px;
      text-align: left;
      border-radius: 100px;

      &.added {
        width: 142px;
      } */


      .addWishListTitle {
        position: absolute;
        font-size: $font-size-base - 0.2;
        color: var(--text-color);
        left: 34px;
        border-radius: 100px;
        white-space: nowrap;
        top: 7px;
        transition: all 0.3s ease-in;
        width: 0;
        display: block;
        overflow: hidden;
      }
    }

    &:hover {
      .poster-title {
        color: var(--primary);
      }

      .posterThumbnail {
        /* transform: translate(2px, -4px); */
        box-shadow: var(--box-shadow-hover);

        img {
          transform: scale(1.15);
        }
      }

      .addWishListBtn {
        display: block;
        transform: translateX(0);

        &:hover {
          background-color: var(--bg);
          width: 130px;
          text-align: left;
          border-radius: 100px;

          &.added {
            width: 142px;
          }

          .addWishListTitle {
            opacity: 1;
            width: 100px;
          }
        }
      }

      .poasterPlayBtn {
        background-color: rgba(0, 0, 0, 0.35);
        opacity: 1;


        .playIcon {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}

.addWishListBlock {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100% !important;
  background-color: var(--secondary);
  /* position: absolute;
  bottom: 0;
  left: 0; */

  span {
    float: left;
    width: 100%;
    display: block;

    padding: 2px 5px;
    text-align: center;
    font-size: $font-size-base - 0.1;
    border-radius: 3px;
  }

  &:hover {
    background-color: var(--primary);
  }
}

.removeWishList {
  margin-top: 0;
}

$poster-wide-mobile: 78%;

.posterWide {
  /* width: $poster-wide-mobile;
  min-width: $poster-wide-mobile;
  max-width: $poster-wide-mobile;
  padding: 5px 5px 10px 5px; */
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  /* max-height: calc(0.56471 * 100%); */

  .posterThumbnail {
    padding-bottom: calc(0.56471 * 100%);
    /* padding-bottom: 144%; */
  }

  /* @include media-breakpoint-up(sm) {
    width: $poster-wide-mobile - 35;
    min-width: $poster-wide-mobile - 35;
    max-width: $poster-wide-mobile - 35;
  }

  @include media-breakpoint-up(md) {
  } */

  @include media-breakpoint-up(lg) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    /* .posterInfo {
      padding-top: 30px;
    } */
  }
}

.addWishList {
  padding: 0;

  &:hover {
    span {
      svg {
        fill: var(--primary) !important;
        color: var(--primary) !important;
      }
    }
  }
}

.movie-model-block {
  text-align: left;
  margin-top: 5px;
}

/* .model-text {
  font-size: $font-size-base - 0.26;
  font-family: $font-family-base-2;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 10px;
  line-height: 1.2;
  border-radius: 3px;
  letter-spacing: 1px;

  &.free {
    background-color: var(--yellow);
    color: var(--dark);
  }

  &.premium {
    background-color: var(--primary);
    color: white;
  }
} */
